iframe {
    display: none !important;
}
body {
    font-family: "Jost", sans-serif;
    font-size: 14px;
    line-height: normal;
}
#root,
.sidebar,
body,
html {
    min-height: 100%;
}
.sidebar {
    background: #eaf1fb !important;
    max-width: 220px;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
}
.header {
    background: #fff;
    border-bottom: 2px solid #4285f4;
}
#logo img {
    max-height: 45px;
}

.navbar-nav .selected.nav-item ul {
    display: block;
}

.navbar-nav .nav-item ul {
    display: none;
}

.sidebar .selected {
    background: rgba(230, 238, 238, 0.25098039215686274);
    padding: 0;
}

.sidebar ul li p {
    display: block;
    color: #333;
    text-decoration: none;
    padding: 10px 20px;
    border-bottom: 1px solid #2c3b4c1f;
    margin: 0;
    line-height: 20px;
}
.sidebar ul li a {
    display: block;
    color: #333;
    text-decoration: none;
    padding: 10px 20px;
    line-height: 20px;
    border-bottom: 1px solid #2c3b4c1f;
}
.sidebar ul li li a {
    padding: 10px 35px;
}

.sidebar .material-symbols-outlined {
    float: left;
    margin-right: 8px;
}
.sidebar .material-symbols-outlined.float-right {
    float: right;
    margin-left: 8px;
}
.sidebar ul li a:hover,
.sidebar ul li p:hover {
    background: #4285f4;
    cursor: pointer;
    color: #fff;
}

.header {
    background: #1167ce;
    padding: 10px 0px;
    min-height: 70px;
}
.menuMain {
}
.menuMain ul {
    float: right;
}
.menuMain ul li {
    display: inline-block;
    position: relative;
}
.menuMain ul li a {
    display: block;
    padding: 8px 16px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    border-radius: 4px;
}
.menuMain ul li a:hover,
.menuMain ul li a.active {
    background: #fff;
    color: #4285f4;
}
.menuMain ul li ul {
    display: none;
    padding: 15px;
    border-radius: 8px;
    position: absolute;
    left: 0px;
    top: 100%;
    width: 300px;
    background: #fff;
    box-shadow: 0px 0px 3px #33333390;
    z-index: 1000;
}
.menuMain ul li:hover ul {
    display: block;
}
.menuMain ul li ul li {
    display: block;
}
.menuMain ul li ul li a {
    color: #333;
    padding: 10px;
    font-weight: normal;
    border-bottom: 1px solid #ccc;
    display: block;
}
.logo {
    text-align: center;
    /* padding: 8px 16px; */
    /* background: #fff; */
    text-align: center;
    border-radius: 4px;
}
.logo img {
    border-radius: 8px;
}
.MyAccountMenu {
    text-align: center;
    border-bottom: 1px solid #ccc;
    background: #fff;
}
.MyAccountMenu ul li {
    display: inline-block;
    cursor: pointer;
}
.MyAccountMenu ul li.active .block_text{
background: #06396c0f;
}
.MyAccountMenu ul li .block_text {
    color: #333;
    text-align: left;
    display: block;
    text-decoration: none;
    padding: 8px 16px;
    border-right: 1px solid #ccc;
}
.MyAccountMenu ul li .block_text span {
    font-size: 12px;
}
.MyAccountMenu ul li .block_text p {
    font-size: 16px;
    font-weight: bold;
}
/* .MyAccountMenu ul li a */
.pageWrapper {
    /* padding: 25px 0px; */
    background: #f5f5f5;
    min-height: 250px;
}
.servicesList .inner {
    padding: 16px;
    background: #fff;
    box-shadow: 0px 0px 5px #ccc;
    border-radius: 8px;
    height: 100%;
}
.servicesList .inner h2 {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 16px;
}
.servicesList .inner .desc {
    padding-bottom: 0px;
    line-height: 21px;
}
.servicesList .inner .type {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 3px 12px;
    font-size: 12px;
    height: 24px;
    line-height: 18px;
    border-radius: 8px;
    background: #1167ce;
    color: #fff;
    width: 120px;
}
.servicesList .inner .price {
    font-size: 14px;
    font-weight: normal;
    padding: 8px 0px;
}
.flex {
    display: flex;
}

.servicesList .inner .desc ul {
    margin-left: 15px;
}
.servicesList .inner .desc ul li {
    padding-bottom: 5px;
}
h1.heading {
    font-size: 28px;
    padding-bottom: 15px;
}
.profileImage {
    margin-bottom: 15px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px #ccc;
    padding: 5px;
    border-radius: 8px;
}
.navigationBar {
    background: #06396c0f;
    padding: 10px;
    margin: 15px 0px;
}
