.loginWrapper {
    background: #0b1d33;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loginBoxBg,
.loginWrapper {
    min-height: 100%;
    width: 100%;
}

.login_admin {
    margin: auto;
    max-width: 600px;
    width: 100%;
    background: #fff;
    color: #000;
    border: 2px solid #fff;
    /* position: absolute; */
    /* left: 50%; */
    /* top: 50%; */
    /* -webkit-transform: translate(-50%, -50%); */
    /* transform: translate(-50%, -50%); */
}

.login_admin {
    border-radius: 10px;
}
.login_admin .card-body {
    padding: 20px 10px;
}
.logoPlaceholder {

    background: #fff;
    text-align: center;
    border-radius: 5px 5px 0 0;

}

.login_admin h3 {
    font-size: 16px;
}

.login_admin .card-title,
.login_admin h3 {
    padding-left: 10px;
    font-weight: 700;
    text-align: center;
    color: #333;
    margin: 20px 0px 5px 0px;
    
}

.text-center {
    text-align: center !important;
}

.login_admin2{ max-width: 450px; margin: auto; padding: 21px;}
.login_admin2 h3{ text-align: center; margin-bottom: 15px;}